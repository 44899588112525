<template>
  <v-card class="titleCard px-5 py-2 ma-6">
    <v-row justify="end" class="py-3" align="center">
      <v-col sm="12" md="2">
        <v-img
            :src="
              require('@/assets/img/CompanyLogo.png')
            "
            :max-height="$vuetify.breakpoint.mobile ? '100' : '100'"
            contain
        ></v-img>
      </v-col>
      <v-chip v-if="company.isSub" color="green" class="on-border-left text-h5 px-5 py-5">
        Субагент
      </v-chip>
      <v-col sm="12" md="10">
        <v-expand-transition>
          <v-row justify="end" align="center" class="right-align">
            <v-col cols="4">
              <div class="text-h4">{{ company.name }}</div>
            </v-col>
            <v-col cols="auto" class="text-center">
              <v-row class="text-center">
                <v-col cols="auto" align-self="center">
                  <span class="text-h5 green--text">
                    Текущий баланс: {{ company.ballance }} Руб
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7" align-self="end">
                  <v-text-field
                      label="Добавить"
                      :rules="[rules.isNumber]"
                      v-model="AddingSum"
                      outlined
                      suffix="Руб"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-subheader>
                    <v-btn
                        :disabled="!sumIsNumber"
                        @click="AddSum(AddingSum)"
                        large
                        color="orange"
                    >Добавить
                    </v-btn>
                  </v-subheader>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto" class="text-center">
              <v-row class="text-center">
                <v-col cols="auto" align-self="center">
                  <span class="text-h5 green--text">
                    Текущий сбор: {{ company.tax }} Руб
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7" align-self="end">
                  <v-text-field
                      label="Установить"
                      :rules="[rules.isNumber, rules.lessThenZero]"
                      v-model="settingTax"
                      outlined
                      suffix="Руб"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-subheader>
                    <v-btn
                        :disabled="!taxIsNumber"
                        @click="SetTax(settingTax)"
                        large
                        color="orange"
                    >Установить
                    </v-btn>
                  </v-subheader>
                </v-col>
              </v-row>
            </v-col>
            <v-row justify="end" align="center" class="right-align">
              <v-col class="mr-6" cols="auto">
                <v-btn color="orange" dark large outlined class="ma-2" @click="openModal">
                  Скачать отчет
                </v-btn>
                <company-report-modal :company="company" :dialog.sync="isModalOpen"/>
              </v-col>
            </v-row>
          </v-row>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import CompanyReportModal from "@/components/CompaniesPage/CompanyReportModal.vue";

export default {
  name: "CompaniesListItem",
  components: {CompanyReportModal},
  props: {
    company: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      AddingSum: 0,
      settingTax: 0,
      isModalOpen: false,
      rules: {
        isNumber: (v) => this.isNumber(v) || "Введите число",
        lessThenZero: (v) => v >= 0 || "Введите число больше 0"
      },
    }
  },
  computed: {
    sumIsNumber() {
      return this.isNumber(this.AddingSum);
    },
    taxIsNumber() {
      return this.isNumber(this.settingTax)
    }
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    isNumber(value) {
      return !isNaN(parseFloat(value));
    },
    async AddSum(sum) {
      await this.$store.dispatch({
        type: "setActions",
        accept: async () => {

          let data = {
            CompanyId: this.company.id,
            Balance: sum
          };
          try {
            let resp = await this.$axios.post(
                this.$enums.Endpoints.Data.AddBallance,
                data
            );
            console.log("API call successful");
            this.$emit('updateBalance', this.company.id, resp.data);
            this.AddingSum = 0;
            return resp.data;
          } catch (e) {
            console.log("API call error");
            this.$store.state.error = {
              show: true,
              value: {
                Code: e.response.status,
                Message: e.response.data.Message,
              },
            };
            return [];
          }
        },
      });
      this.$store.commit("setMessage", `Вы уверены, что хотите добавить ${sum} к балансу`);
      this.$store.commit("openDialog", true);
    },
    async SetTax(tax) {
      await this.$store.dispatch({
        type: "setActions",
        accept: async () => {

          let data = {
            CompanyId: this.company.id,
            Tax: tax
          };
          try {
            let resp = await this.$axios.post(
                this.$enums.Endpoints.Data.EditTax,
                data
            );

            console.log("API call successful");
            this.$emit('updateTax', this.company.id, resp.data);
            this.settingTax = 0;
            return resp.data;
          } catch (e) {
            console.log("API call error");
            this.$store.state.error = {
              show: true,
              value: {
                Code: e.response.status,
                Message: e.response.data.Message,
              },
            };
            return [];
          }
        },
      });
      this.$store.commit("setMessage", `Вы уверены, что хотите установить сбор в размере ${tax}`);
      this.$store.commit("openDialog", true);
    },
  }
};

</script>

<style scoped>
@media (max-width: 600px) {
  .on-border-right {
    transform: translateX(-50%);
    left: 50%;
    right: inherit !important;
  }
  .on-border-left {
    transform: translateX(50%);
    right: 50%;
    left: inherit !important;
  }
}
.on-border-right {
  position: absolute;
  top: -15px;
  right: 20px;
}
.on-border-left {
  position: absolute;
  top: -15px;
  left: 20px;
}

.titleCard {
  border: solid 2px orange !important;
}
</style>