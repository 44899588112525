<template>
  <v-dialog v-model="dialog" persistent max-width="600px" @click:outside="closeDialog">
    <v-card>
      <v-card-title class="headline">Выберите даты</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <custom-date-picker
                  outlined
                  v-model="startDate"
                  text="Начальная дата"
                  format="dd.MM.yyyy"
              />
            </v-col>
            <v-col cols="12" md="6">
              <custom-date-picker
                  outlined
                  v-model="endDate"
                  text="Конечная дата"
                  format="dd.MM.yyyy"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn color="green" @click="submitForm">Получить отчет</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomDatePicker from "@/components/UI/CustomDatePicker.vue";
import {downloadFile} from "@/mixins/utils";

export default {
  components: {
    CustomDatePicker,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedCompany: null,
      startDate: null,
      endDate: null,
    };
  },
  mounted() {
    this.selectedCompany = this.company;
  },
  methods: {
    closeDialog() {
      this.$emit('update:dialog', false);
    },
    async downloadReport() {
      try {
        this.isDownload = true;

        const startDateWithOneDayAdded = new Date(this.startDate);
        startDateWithOneDayAdded.setDate(
            startDateWithOneDayAdded.getDate() + 1
        );

        const endDateWithOneDayAdded = new Date(this.endDate);
        endDateWithOneDayAdded.setDate(endDateWithOneDayAdded.getDate() + 1);

        const requestData = {
          companyCode: this.selectedCompany.code,
          start: startDateWithOneDayAdded,
          end: endDateWithOneDayAdded,
        };

        let resp = await this.$axios.get(
            this.$enums.Endpoints.Other.SaleRegister,
            {
              params: requestData,
              responseType: "arraybuffer",
            }
        );
        this.isDownload = false;
        downloadFile(resp.data, {
          openInNewTab: false,
          type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          name: "Реестр продаж.xlsx",
        });
      } catch (error) {
        console.error("Ошибка при загрузке отчёта:", error);
      }
    },
    async submitForm() {
      await this.downloadReport();
    },
  },
};
</script>